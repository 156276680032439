import React from 'react';
import './About.css'; // Importar el archivo CSS para estilos

function About() {
  return (
    <div id="about" className="about">
      <h2>Acerca de mi </h2>
      <p>
        Hola y en esta oportunidad estaremos viendo como hacer nuestro sitio con react + github actions + github pages.
      </p>
     
      <p>
       Feliz viernes :D
      </p>
    </div>
  );
}

export default About;
